<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div
      class="body-container"
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-bottom': '30px' }
          : { 'padding-bottom': '70px' },
      ]"
    >
      <v-container class="px-10 px-md-auto">
        <div>
          <div class="text-h2 text-md-h1 font-weight-bold mx-0">
            Proveedores Zaturna
          </div>
          <div
            class="flex-row text-subtitle-1 text-sm-h4 font-weight-regular mb-4 ml-md-1"
            :class="loadingFirstProviders && 'mb-16 mb-sm-12 pb-2 pb-sm-0'"
          >
            <div v-if="!loadingFirstProviders">
              <span class="d-flex d-sm-inline-flex">
                <!-- Format numbers with commas -->
                {{
                  providersQuantity !== 1
                    ? providersQuantity +
                      " proveedores " +
                      (filtersUsed ? "encontrados" : "registrados")
                    : providersQuantity +
                      " proveedor " +
                      (filtersUsed ? "encontrado" : "registrado")
                }}
              </span>
              <span class="d-flex d-sm-inline-flex mx-sm-8">
                <v-icon class="mr-2 secondary--text" small>fa-map-pin</v-icon>
                <!-- Format numbers with commas -->
                {{
                  citiesQuantity != 1
                    ? citiesQuantity + " ciudades"
                    : citiesQuantity + " ciudad"
                }}
              </span>
            </div>
          </div>
        </div>
        <v-divider class="mx-n2 secondary" />
        <CardsFilters
          :filters="filters"
          :filters-used="filtersUsed"
          @removeFilters="removeFilters"
        />
        <div
          v-if="
            !loadingFirstProviders &&
            !loadingMoreProviders &&
            providersQuantity === 0
          "
          class="my-16 text-center descent--text text--darken-2 text-h4 font-weight-regular"
        >
          No se han encontrado proveedores
        </div>
      </v-container>
      <InfiniteListCards
        v-if="!filtersUsed || providersQuantity !== 0"
        :cards="providers"
        :no-more-cards="noMoreProviders"
        :loading-first-cards="loadingFirstProviders"
        :loading-more-cards="loadingMoreProviders"
        type="Provider"
        @handleMoreCards="fetchMoreProviders"
      />
      <v-container
        v-if="loadingFirstProviders || loadingMoreProviders"
        class="d-flex flex-column align-center my-10 my-sm-12"
      >
        <v-progress-circular indeterminate color="secondary" :size="100" />
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/LandingPage/Shared/Navbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import CardsFilters from "@/components/Shared/CardsFilters.vue";
import InfiniteListCards from "@/components/LandingPage/Shared/InfiniteListCards.vue";
import Footer from "@/components/LandingPage/Shared/Footer.vue";
import {
  GET_PROVIDERS_WITHOUT_CURSOR,
  GET_PROVIDERS_WITH_CURSOR,
  GET_PROVIDERS_DISTINCT_LOCATIONS,
} from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import { getDistinctCitiesByState } from "@/Utils/locations.js";

export default {
  name: "Providers",
  components: {
    Navbar,
    SearchBar,
    CardsFilters,
    InfiniteListCards,
    Footer,
  },
  data: () => ({
    filters: [
      {
        type: "search",
        name: "Nombre",
        model: "",
      },
      {
        type: "location",
        state: {
          name: "Estado",
          model: "",
          options: [],
        },
        city: {
          name: "Ciudad",
          model: "",
          options: getDistinctCitiesByState,
          distinctOptions: [],
        },
      },
      // {
      //   type: "rating",
      //   name: "Calificación",
      //   emptyIcon: "far fa-star text--lighten-4",
      //   fullIcon: "fa-star secondary--text",
      //   model: null,
      //   menuModel: false,
      // },
    ],
    providers: [],
    providersQuantity: 0,
    citiesQuantity: 0,
    noMoreProviders: false,
    loadingFirstProviders: true,
    loadingMoreProviders: false,
  }),
  computed: {
    providersPerPageQuantity() {
      return this.$vuetify.breakpoint.xs
        ? 6
        : this.$vuetify.breakpoint.sm
        ? 10
        : 16;
    },
    filtersValues() {
      return [
        this.filters[0].model,
        this.filters[1].state.model,
        this.filters[1].city.model,
      ];
    },
    filtersUsed() {
      if (
        this.filters[0].model ||
        this.filters[1].state.model ||
        this.filters[1].city.model
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    "$route.query.nombre": {
      async handler(val) {
        if (!val) return;
        this.filters[0].model = val;
      },
      immediate: true,
    },
    "$route.query.estado": {
      async handler(val) {
        if (!val) return;
        this.filters[1].state.model = val;
      },
      immediate: true,
    },
    "$route.query.ciudad": {
      async handler(val) {
        if (!val) return;
        this.filters[1].city.model = val;
      },
      immediate: true,
    },
    filtersValues: function () {
      this.fetchFirstProviders();
    },
  },
  created() {
    this.fetchFirstProviders();
  },
  methods: {
    loadFilters(props) {
      if (this.filters[0].model !== "") {
        props = { ...props, name: this.filters[0].model };
      }
      if (this.filters[1].state.model !== "") {
        props = { ...props, state: this.filters[1].state.model };
        if (this.filters[1].city.model !== "") {
          props = { ...props, city: this.filters[1].city.model };
        }
      }
      return props;
    },
    async fetchDistinctLocations(props) {
      const { data, errors } = await useQuery(
        GET_PROVIDERS_DISTINCT_LOCATIONS,
        props
      );
      if (data) {
        this.filters[1].state.options = data.distinctProvidersLocations.states;
        this.filters[1].city.distinctOptions =
          data.distinctProvidersLocations.cities;
      } else if (errors) {
        console.log(errors);
      }
    },
    async fetchFirstProviders() {
      this.loadingFirstProviders = true;
      this.noMoreProviders = false;
      let props = { perPage: this.providersPerPageQuantity };
      props = this.loadFilters(props);

      this.fetchDistinctLocations(props);

      const { data, errors } = await useQuery(
        GET_PROVIDERS_WITHOUT_CURSOR,
        props
      );
      if (data) {
        this.providers = this.formatProviders(data.providers.providers);
        this.cursor = data.providers.cursor;
        this.providersQuantity = data.countProviders.providersCount;
        this.citiesQuantity = data.countProviders.providersCitiesCount;
        if (this.providersQuantity <= this.providersPerPageQuantity) {
          this.noMoreProviders = true;
        }
      } else if (errors) {
        console.log(errors);
      }
      this.loadingFirstProviders = false;
    },
    async fetchMoreProviders() {
      if (
        !this.loadingFirstProviders &&
        !this.loadingMoreProviders &&
        !this.noMoreProviders
      ) {
        this.loadingMoreProviders = true;
        let props = {
          perPage: this.providersPerPageQuantity,
          cursor: this.cursor,
        };
        props = this.loadFilters(props);

        const { data, errors } = await useQuery(
          GET_PROVIDERS_WITH_CURSOR,
          props
        );
        if (data) {
          const newProvidersToAppend = this.formatProviders(
            data.providers.providers
          );
          this.providers = [...this.providers, ...newProvidersToAppend];
          this.cursor = data.providers.cursor;
          this.providersQuantity = data.countProviders.providersCount;
          this.citiesQuantity = data.countProviders.providersCitiesCount;
          if (this.providersQuantity === this.providers.length) {
            this.noMoreProviders = true;
          }
        } else if (errors) {
          console.log(errors);
        }
        this.loadingMoreProviders = false;
      }
    },
    formatProviders(providers) {
      const data = providers.map((provider) => {
        const info = {
          ...provider,
          rating:
            provider.providerComment.reduce(
              (total, currentValue) => total + currentValue.score,
              0
            ) / provider.providerComment.length,
          reviews: provider.providerComment.length,
          location: provider.city + ", " + provider.state,
        };
        return info;
      });
      return data;
    },
    removeFilters() {
      this.$router.push({ query: null }).catch(() => {
        this.filters.forEach((filter) => {
          if (filter.type === "location") {
            filter.state.model = "";
            filter.city.model = "";
          } else if (filter.type === "rating") {
            filter.model = 0;
          } else {
            filter.model = "";
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}
.slide-y-transition-leave-to {
  transform: none;
}
</style>
