<template>
  <div v-if="dialog">
    <v-dialog
      v-model="dialog"
      max-width="750px"
      content-class="elevation-0"
      persistent
    >
      <FormLogin
        v-if="modalType === 'login'"
        :redirect-login="redirectLogin"
        @closeModal="closeModal"
        @changeType="changeType"
      />
      <FormRegister
        v-else-if="modalType === 'register'"
        :redirect-register="redirectRegister"
        :title="registerTitle"
        :subtitle="registerSubtitle"
        @closeModal="closeModal"
        @changeType="changeType"
      />
    </v-dialog>
  </div>
</template>

<script>
import FormLogin from "@/components/LandingPage/Shared/Modal/FormLogin.vue";
import FormRegister from "@/components/LandingPage/Shared/Modal/FormRegister.vue";

export default {
  name: "ModalLoginRegister",
  components: { FormLogin, FormRegister },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: "login",
    },
    redirectLogin: {
      type: String,
      default: "UserMainPage",
    },
    redirectRegister: {
      type: String,
      default: "UserMainPage",
    },
    registerTitle: {
      type: String,
      default: "Regístrate",
    },
    registerSubtitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.changeType("login");
    },
    changeType(type) {
      this.$emit("handleModalType", type);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-select__selection,
::v-deep .v-select__selection--comma,
::v-deep .v-select.v-text-field input {
  color: black !important;
}
</style>
