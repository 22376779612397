<template>
  <v-hover v-slot="{ hover }">
    <v-card
      v-ripple="{
        class: 'secondary--text text--lighten-1',
        center: true,
      }"
      tile
      :max-width="
        $vuetify.breakpoint.xs ? '268' : $vuetify.breakpoint.sm ? '245' : '270'
      "
      class="d-flex flex-column justify-space-between"
      :to="getRoute"
    >
      <div>
        <v-img
          width="270"
          height="270"
          :src="image"
          class="text-right pa-4 grey lighten-4"
        >
          <!-- <Button
            v-if="user"
            outlined
            color="white"
            rounded="xs"
            :height="40"
            :icon="favorite ? 'fa-heart' : 'far fa-heart'"
            :aria-label="
              favorite
                ? 'Remover proveedor de favoritos'
                : 'Añadir proveedor a favoritos'
            "
            @event="setFavorite()"
          /> -->
        </v-img>
        <v-container class="pa-1 pt-0">
          <v-card-title
            class="card-title break-word text-body-1 text-md-h4 text-left pt-6 mb-1"
          >
            {{ card.name }}
          </v-card-title>
          <v-card-subtitle
            class="break-word text-body-2 font-weight-bold secondary--text text-left pb-3"
          >
            {{
              type == "Provider"
                ? card.location
                : (type == "Service" ||
                    type == "ProviderServicesDetails" ||
                    type == "Package" ||
                    type == "ProviderPackagesDetails") &&
                  card.providerName
            }}
          </v-card-subtitle>
          <v-card-text>
            <v-row v-if="card.rating" class="mx-0">
              <v-rating
                readonly
                dense
                half-increments
                size="14"
                empty-icon=""
                full-icon="fa-star"
                half-icon="fa-star-half-alt"
                color="black"
                background-color="descent lighten-1"
                class="mb-1"
                :value="card.rating"
              />
              <span class="text-caption align-self-center black--text mx-2">
                {{ card.rating }}
              </span>
              <span class="text-caption align-self-center black--text">
                ({{ card.reviews }}
                {{ card.reviews == 1 ? "reseña" : "reseñas" }})
              </span>
            </v-row>
          </v-card-text>
        </v-container>
      </div>
      <v-card-actions>
        <v-btn
          class="text-body-2 mx-1 my-2"
          :class="hover && 'v-btn--active'"
          plain
          small
          :ripple="false"
          :to="getRoute"
        >
          Ver más ›
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
// import Button from "@/components/Shared/Button.vue";
import { mapState } from "vuex";

export default {
  name: "Card",
  components: {
    // Button,
  },
  props: {
    card: { type: Object, required: true },
    type: { type: String, required: true },
  },
  data: () => ({
    // ! Change way of getting card favorite state
    favorite: false,
  }),
  computed: mapState({
    user(state) {
      return !!state.user;
    },
    image() {
      if (this.type === "Provider") {
        return this.card.photoUrl;
      } else if (
        this.type === "Service" ||
        this.type === "ProviderServicesDetails"
      ) {
        return this.card.serviceImage[0].url;
      } else if (
        this.type === "Package" ||
        this.type === "ProviderPackagesDetails"
      ) {
        return this.card.packageImages[0].url;
      } else {
        return "";
      }
    },
    getRoute() {
      let routeName = this.type;
      if (this.$router.history.current.path.includes("usuario")) {
        routeName = "User" + this.type;
      }
      return {
        name: routeName,
        params: {
          id: this.card.id,
          data: this.card,
        },
      };
    },
  }),
  methods: {
    setFavorite() {
      this.favorite = !this.favorite;
    },
  },
};
</script>

<style scoped lang="scss">
.card-title {
  line-height: 22px;
}
</style>
