<template>
  <div class="d-flex flex-wrap align-center mt-5">
    <span class="mr-3 py-2 text-h3 secondary--text font-weight-bold">
      Filtros:
    </span>
    <div class="d-flex flex-wrap align-center my-1 my-sm-0 mr-4 text-h4">
      <div class="d-flex flex-wrap align-center mr-3">
        <span v-for="(filter, i) in filters" :key="i" class="d-flex flex-wrap">
          <v-btn
            v-if="filter.model"
            outlined
            height="44"
            :ripple="false"
            class="rounded-pill btn-background-purple text-h4 secondary--text my-1 mr-2"
            @click="clearSearch(filter)"
          >
            <v-rating
              v-if="filter.type === 'rating'"
              v-model.number="filter.model"
              dense
              readonly
              size="20"
              :empty-icon="filter.emptyIcon"
              :full-icon="filter.fullIcon"
              color="secondary"
              background-color="grey lighten-1"
            />
            <span v-else> {{ filter.model }} </span>
            <v-icon small right> fa-times </v-icon>
          </v-btn>
          <div v-else-if="filter.type === 'location' && filter.state.model">
            <Button
              :text="filter.state.model"
              outlined
              pill
              active
              :height="44"
              append-icon="fa-times"
              class="my-1 mr-2"
              aria-label="Remover filtro de estado"
              @event="clearLocationSearch(filter, true)"
            />
            <Button
              v-if="filter.city.model"
              :text="filter.city.model"
              outlined
              pill
              active
              :height="44"
              append-icon="fa-times"
              class="my-1 mr-2"
              aria-label="Remover filtro de ciudad"
              @event="clearLocationSearch(filter, false)"
            />
          </div>
          <v-menu
            v-else-if="filter.type === 'rating'"
            offset-y
            rounded="md"
            transition="slide-y-transition"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, value }">
              <span v-on="on">
                <Button
                  :text="filter.name"
                  outlined
                  pill
                  :height="44"
                  :active="value"
                  :aria-label="`Seleccionar ${filter.name}`"
                  class="my-1 mr-2"
                />
              </span>
            </template>
            <v-card tile class="pa-4">
              <div class="pl-2 pb-2 text-h4 font-weight-bold">
                {{ filter.name }}
              </div>
              <v-rating
                v-model.number="filter.model"
                dense
                hover
                size="30"
                :ripple="false"
                :empty-icon="filter.emptyIcon"
                :full-icon="filter.fullIcon"
                color="secondary"
                background-color="grey lighten-2"
                class="pl-1"
              />
            </v-card>
          </v-menu>
          <v-sheet v-else-if="filter.type === 'options'" width="205">
            <v-autocomplete
              ref="aut"
              v-model="filter.model"
              :autofocus="filter.autofocus"
              :items="filter.options"
              :no-data-text="filter.noDataText"
              hide-details
              auto-select-first
              single-line
              outlined
              rounded
              solo
              height="44"
              dense
              flat
              color="secondary"
              background-color="transparent"
              item-color="secondary"
              autocomplete="null"
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              class="btn-background-transparent btn-hover-purple text-h4 rounded-pill my-1 mr-2 v-input--is-focused"
              :class="filter.model && 'btn-background-purple'"
            >
              <template v-slot:selection="{ item }">
                <span class="text-h4 secondary--text">{{ item }}</span>
              </template>
              <template v-slot:label>
                <span class="text-h4 secondary--text">{{ filter.name }}</span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon
                    size="30"
                    color="secondary"
                    class="v-input__icon--append mr-n3"
                  >
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-autocomplete>
          </v-sheet>
          <v-sheet v-else-if="filter.type === 'location'" width="140">
            <v-autocomplete
              ref="aut"
              v-model="filter.state.model"
              :items="filter.state.options"
              :no-data-text="
                !filter.state.model
                  ? 'No se encontraron estados con filtros especificados'
                  : 'Estado no encontrado'
              "
              hide-details
              auto-select-first
              single-line
              outlined
              rounded
              solo
              height="44"
              dense
              flat
              color="secondary"
              background-color="transparent"
              item-color="secondary"
              autocomplete="null"
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              class="btn-background-transparent btn-hover-purple text-h4 rounded-pill my-1 mr-2 v-input--is-focused"
              :class="filter.state.model && 'btn-background-purple'"
            >
              <template v-slot:selection="{ item }">
                <span class="text-h4 secondary--text">{{ item }}</span>
              </template>
              <template v-slot:label>
                <span class="text-h4 secondary--text">{{
                  filter.state.name
                }}</span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon
                    size="30"
                    color="secondary"
                    class="v-input__icon--append mr-n3"
                  >
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-autocomplete>
          </v-sheet>
          <v-sheet
            v-if="filter.type === 'location' && !filter.city.model"
            width="140"
          >
            <v-autocomplete
              ref="aut"
              v-model="filter.city.model"
              :items="
                filter.city.options(
                  filter.state.model,
                  filter.city.distinctOptions
                )
              "
              :no-data-text="
                filter.state.model
                  ? 'Ciudad no encontrada'
                  : 'Seleccione un estado'
              "
              hide-details
              auto-select-first
              single-line
              outlined
              rounded
              solo
              height="44"
              dense
              flat
              color="secondary"
              background-color="transparent"
              item-color="secondary"
              autocomplete="null"
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              class="btn-background-transparent btn-hover-purple text-h4 rounded-pill my-1 mr-2 v-input--is-focused"
              :class="filter.city.model && 'btn-background-purple'"
            >
              <template v-slot:selection="{ item }">
                <span class="text-h4 secondary--text">{{ item }}</span>
              </template>
              <template v-slot:label>
                <span class="text-h4 secondary--text">{{
                  filter.city.name
                }}</span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon
                    size="30"
                    color="secondary"
                    class="v-input__icon--append mr-n3"
                  >
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-autocomplete>
          </v-sheet>
        </span>
      </div>
      <a
        v-if="filtersUsed"
        class="text-decoration-underline secondary--text text-h4 my-3"
        @click="removeFilters"
      >
        Eliminar filtros
      </a>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";

export default {
  name: "CardsFilters",
  components: {
    Button,
  },
  props: {
    filters: { type: Array, required: true },
    filtersUsed: { type: Boolean, required: true },
  },
  mounted() {
    if (this.$props.filters[0].autofocus) {
      this.autofocusServices();
    }
  },
  emits: ["removeFilters"],
  methods: {
    clearSearch(filter) {
      if (filter.type === "rating") {
        filter.model = 0;
      } else {
        filter.model = "";
      }
    },
    clearLocationSearch(filter, clearBoth) {
      if (clearBoth) {
        filter.state.model = "";
      }
      filter.city.model = "";
    },
    autofocusServices() {
      this.$refs.aut[0].isMenuActive = true;
      this.$refs.aut[0].isFocused = true;
    },
    removeFilters() {
      this.$emit("removeFilters");
    },
  },
};
</script>

<style scoped lang="scss">
.btn-background-transparent::before {
  background-color: transparent !important;
}

.btn-background-purple {
  background-color: rgba(98, 37, 130, 0.1) !important;
}

.btn-hover-purple:focus-within {
  background-color: rgba(98, 37, 130, 0.1) !important;
}

.slide-y-transition-leave-to {
  transform: none;
}
</style>
