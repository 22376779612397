<template>
  <div>
    <v-card v-if="!forgotPassword" class="pa-8 pa-sm-12">
      <v-card-actions class="d-flex justify-end pa-0">
        <v-btn
          icon
          :ripple="false"
          absolute
          right
          top
          class="btn-background-transparent hover-close-btn"
          @click="closeModal"
        >
          <v-icon large color="secondary">mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text class="pa-0">
        <div
          class="text-h3 text-sm-h2 black--text text-center font-weight-bold pt-4 pb-9"
        >
          Inicia sesión
        </div>
        <v-text-field
          v-model="email"
          type="email"
          outlined
          label="Correo electrónico"
          color="secondary"
          class="v-input--is-focused"
        >
        </v-text-field>
        <v-text-field
          v-model="password"
          outlined
          label="Contraseña"
          color="secondary"
          class="v-input--is-focused"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          @keyup.enter="login"
        >
        </v-text-field>
      </v-card-text>
      <v-card-actions class="px-0">
        <Button
          text="Iniciar sesión"
          block
          :loading="loading"
          class="mx-0 mt-3 ma-md-0"
          aria-label="Iniciar sesión"
          @event="login()"
        />
      </v-card-actions>
      <v-card-actions class="justify-start px-0">
        <a
          text
          class="text-decoration-underline black--text font-weight-medium mb-4 text-h4"
          color="black"
          @click="handleForgotPassword"
        >
          ¿Olvidaste tu contraseña?
        </a>
      </v-card-actions>

      <v-divider />
      <!-- <v-card-actions class="mt-6">
          <v-btn
            elevation="0"
            outlined
            class="text-none font-weight-medium text-h6"
            block
            x-large
            disabled
            @click="changeType"
          >
            <v-icon left size="28"> mdi-google </v-icon>
            <div class="ml-8">Continuar con Google</div>
          </v-btn>
        </v-card-actions> -->
      <!-- <v-card-actions class="mb-6">
          <v-btn
            elevation="0"
            outlined
            class="text-none font-weight-medium text-h6"
            block
            x-large
            disabled
            @click="changeType"
          >
            <v-icon left size="28"> mdi-facebook </v-icon>
            <div class="ml-8">Continuar con Facebook</div>
          </v-btn>
        </v-card-actions> -->

      <v-card-actions class="px-0 text-h4">
        <span class="mr-2"> ¿No tienes cuenta? </span>
        <a
          text
          class="text-none font-weight-bold black--text"
          @click="changeType"
        >
          Regístrate aquí
        </a>
      </v-card-actions>
    </v-card>

    <div v-else>
      <v-card v-if="!requestCode" class="pa-8">
        <v-form ref="passwordRecoverForm" v-model="valid" lazy-validation>
          <v-card-text class="pa-0">
            <div
              class="text-h3 text-sm-h2 text-center font-weight-bold pt-4 pb-9"
            >
              Recuperar Contraseña
            </div>
            <v-text-field
              v-model="email"
              outlined
              label="Correo electrónico"
              color="secondary"
              class="v-input--is-focused"
              :rules="emailRules"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions class="pa-0">
            <Button
              text="Cambiar contraseña"
              block
              class="mx-0 mt-3 ma-md-0"
              aria-label="Cambiar contraseña"
              @event="handleRequestCode()"
            />
          </v-card-actions>
          <v-card-actions class="justify-center px-0">
            <a
              text
              class="text-decoration-underline black--text font-weight-medium ma-1 text-h4"
              color="black"
              @click="handleForgotPassword()"
            >
              Cancelar
            </a>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-card v-else class="pa-8 pa-sm-12">
        <v-form ref="newPasswordForm" v-model="valid" lazy-validation>
          <v-card-text class="pa-0">
            <div class="text-h3 text-sm-h2 text-center font-weight-bold pb-6">
              Nueva Contraseña
            </div>
            <div class="text-h4 text-center py-4 font-weight-medium">
              Revisa tu correo para obtener el código
            </div>
            <v-text-field
              v-model="code"
              outlined
              label="Código"
              color="secondary"
              class="v-input--is-focused"
              :rules="codeRules"
            >
            </v-text-field>
            <v-text-field
              v-model="password"
              outlined
              label="Nueva Contraseña"
              color="secondary"
              class="v-input--is-focused"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions class="px-0">
            <Button
              text="Guardar nueva contraseña"
              block
              :loading="loading"
              aria-label="Guardar nueva contraseña"
              @event="resetPassword()"
            />
          </v-card-actions>
          <v-card-actions class="justify-center px-0">
            <a
              text
              class="text-decoration-underline black--text font-weight-medium"
              color="black"
              @click="handleCancelNewPassword"
            >
              Cancelar
            </a>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import { Auth } from "aws-amplify";
import { mapState } from "vuex";
import { errorMessageCognito } from "@/Utils/awsCognito.js";
import { codeRules, emailRules, passwordRules } from "@/Utils/rules.js";

export default {
  name: "FormLogin",
  components: {
    Button,
  },
  props: {
    redirectLogin: {
      type: String,
      default: "UserMainPage",
    },
  },
  data() {
    return {
      loading: false,
      valid: true,
      showPassword: false,
      forgotPassword: false,
      requestCode: false,
      email: "",
      password: "",
      code: "",
      codeRules: codeRules,
      emailRules: emailRules,
      passwordRules: passwordRules,
    };
  },
  computed: mapState({
    user(state) {
      return state.user;
    },
  }),
  created() {},
  methods: {
    handleAlert({ type, message }) {
      this.$store.dispatch("handleAlert", {
        type: type,
        message: message,
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
    changeType() {
      this.$emit("changeType", "register");
    },
    handleForgotPassword() {
      this.forgotPassword = !this.forgotPassword;
    },
    handleCancelNewPassword() {
      this.requestCode = !this.requestCode;
    },
    async handleRequestCode() {
      if (this.$refs.passwordRecoverForm.validate()) {
        this.loading = true;
        try {
          await Auth.forgotPassword(this.email);
          this.requestCode = !this.requestCode;
        } catch (error) {
          const message = errorMessageCognito(
            error,
            "Error al recuperar contraseña"
          );
          this.handleAlert({
            type: "error",
            message: message,
          });
          console.log(error);
        }
        this.loading = false;
      }
    },
    async resetPassword() {
      if (this.$refs.newPasswordForm.validate()) {
        this.loading = true;
        try {
          await Auth.forgotPasswordSubmit(this.email, this.code, this.password);
          this.handleAlert({
            type: "success",
            message: "Cambio de contraseña exitoso",
          });
          this.handleForgotPassword();
        } catch (error) {
          const message = errorMessageCognito(
            error,
            "Error al recuperar contraseña"
          );
          this.handleAlert({
            type: "error",
            message: message,
          });
          console.log(error);
        }
        this.loading = false;
      }
    },
    async login() {
      if (this.email && this.password) {
        this.loading = true;
        try {
          // const user = await Auth.signIn(this.email, this.password);
          // this.$store.dispatch("signedIn", true);
          // this.$store.dispatch("saveCognitoUser", user);
          const user = await Auth.signIn(this.email, this.password);
          // await Auth.signIn(this.email, this.password);
          await this.$store.dispatch("saveCognitoUser", user);
          await this.$store.dispatch("fetchUser");
          this.handleAlert({
            type: "success",
            message: "Ingresó Correctamente",
          });
          this.closeModal();
          this.$router.push({
            name: this.redirectLogin,
          });
        } catch (error) {
          await Auth.signOut();
          const message = errorMessageCognito(error, "Error al ingresar");
          this.handleAlert({
            type: "error",
            message: message,
          });
          console.log(error);
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-background-transparent::before {
  background-color: transparent !important;
}

.hover-close-btn:hover .v-icon {
  transform: scale(1.1);
}
</style>
