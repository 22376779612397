<template>
  <div>
    <v-card v-if="stepRegister === 'register'" class="pa-8 pa-sm-12">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-actions class="d-flex justify-end pa-0">
          <v-btn
            icon
            :ripple="false"
            absolute
            right
            top
            class="btn-background-transparent hover-close-btn"
            @click="closeModal"
          >
            <v-icon large color="secondary">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="pa-0">
          <div
            class="text-h3 text-sm-h2 text-center font-weight-bold pt-4 pb-9"
          >
            {{ title }}
          </div>
          <div v-if="subtitle" class="text-body-1 text-center pb-4">
            {{ subtitle }}
          </div>
          <v-text-field
            v-model="username"
            outlined
            type="text"
            label="Nombre legal"
            color="secondary"
            class="v-input--is-focused"
            autocomplete="name"
            maxlength="50"
            counter="50"
            :rules="legalNameRules"
          />
          <v-text-field
            v-model="preferedName"
            outlined
            label="Nombre preferido"
            color="secondary"
            class="v-input--is-focused"
            autocomplete="nickname"
            maxlength="50"
            counter="50"
            :rules="preferedNameRules"
          />
          <v-text-field
            v-model="email"
            type="email"
            outlined
            label="Correo electrónico"
            color="secondary"
            class="v-input--is-focused"
            autocomplete="email"
            maxlength="100"
            :rules="emailRules"
          />
          <v-text-field
            v-model="phone"
            outlined
            type="phone"
            label="Télefono"
            color="secondary"
            class="v-input--is-focused"
            maxlength="10"
            :counter="maxDigits"
            autocomplete="tel"
            :rules="phoneRules"
          />
          <v-autocomplete
            v-model="state"
            :items="states"
            auto-select-first
            outlined
            flat
            background-color="transparent"
            color="secondary"
            item-color="secondary"
            label="Estado"
            no-data-text="Estado no encontrado"
            :menu-props="{
              bottom: true,
              closeOnContentClick: true,
              allowOverflow: true,
              offsetY: true,
              transition: 'slide-y-transition',
            }"
            class="v-input--is-focused secondary--text"
            autocomplete="state"
            :rules="stateRulesSelect"
            @change="stateUpdated"
          >
            <template v-slot:append>
              <v-icon size="25" color="secondary"> mdi-chevron-down </v-icon>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-model="city"
            :items="citySelect"
            auto-select-first
            outlined
            flat
            background-color="transparent"
            color="secondary"
            item-color="secondary"
            label="Ciudad"
            no-data-text="Seleccione un estado"
            :menu-props="{
              bottom: true,
              closeOnContentClick: true,
              allowOverflow: true,
              offsetY: true,
              transition: 'slide-y-transition',
            }"
            class="v-input--is-focused secondary--text"
            autocomplete="city"
            :rules="cityRulesSelect"
          >
            <template v-slot:append>
              <v-icon size="25" color="secondary"> mdi-chevron-down </v-icon>
            </template>
          </v-autocomplete>
          <v-text-field
            v-model="password"
            outlined
            label="Contraseña"
            color="secondary"
            class="v-input--is-focused"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="new-password"
            :rules="passwordRules"
            @click:append="showPassword = !showPassword"
          />
          <v-text-field
            v-model="confirmPassword"
            outlined
            label="Confirmar Contraseña"
            color="secondary"
            class="v-input--is-focused"
            :type="showPasswordConfirm ? 'text' : 'password'"
            :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="off"
            :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
            @click:append="showPasswordConfirm = !showPasswordConfirm"
          />
          <v-checkbox
            required
            color="secondary"
            :ripple="false"
            :rules="checkBoxRules"
            class="ma-0"
          >
            <template slot="label">
              <div class="text-h4 black--text">
                <span> Acepto los </span>
                <a
                  class="black--text text-decoration-underline"
                  @click.prevent.stop="handleTermsRoute()"
                >
                  Términos y Condiciones Zaturna
                </a>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            color="secondary"
            :ripple="false"
            required
            :rules="checkBoxRules"
            class="ma-0"
          >
            <template slot="label">
              <div class="text-h4 black--text">
                <span> Acepto las </span>
                <a
                  class="black--text text-decoration-underline"
                  @click.prevent.stop="handleTermsRoute()"
                >
                  Políticas de Privacidad Zaturna
                </a>
              </div>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-card-actions class="mt-2 px-0">
          <Button
            text="Registrar"
            block
            :loading="loading"
            class="mx-0 mt-3 ma-md-0"
            aria-label="Registrar cuenta"
            @event="register()"
          />
        </v-card-actions>
        <!-- <v-card-actions class="justify-start">
          <a
            text
            class="text-decoration-underline black--text font-weight-medium my-3"
            @click="handleStep('verifyEmail')"
          >
            Verificar cuenta
          </a>
        </v-card-actions> -->

        <!-- <v-divider class="mt-4"></v-divider>
        <v-card-actions class="mt-6">
          <v-btn
            elevation="0"
            outlined
            class="text-none font-weight-medium text-h6"
            block
            x-large
            disabled
            @click="changeType"
          >
            <v-icon left size="28"> mdi-google </v-icon>
            <div class="ml-8">Continuar con Google</div>
          </v-btn>
        </v-card-actions>
        <v-card-actions class="mb-6">
          <v-btn
            elevation="0"
            outlined
            class="text-none font-weight-medium text-h6"
            block
            x-large
            disabled
            @click="changeType"
          >
            <v-icon left size="28"> mdi-facebook </v-icon>
            <div class="ml-8">Continuar con Facebook</div>
          </v-btn>
        </v-card-actions> -->

        <v-card-actions class="px-0">
          <span class="mr-2 text-h4">
            ¿Ya tienes cuenta?
            <a
              text
              class="text-decoration-underline black--text font-weight-medium"
              @click="changeType"
            >
              Inicia sesión aquí
            </a>
          </span>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-card v-else-if="stepRegister === 'verifyEmail'" class="pa-8 pa-sm-12">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text class="px-0">
          <div
            class="text-h5 text-sm-h4 text-center font-weight-bold pt-4 pb-9"
          >
            Verificar Cuenta
          </div>
          <v-text-field
            v-model="email"
            outlined
            placeholder="Correo electrónico"
            color="secondary"
            class="v-input--is-focused"
            :rules="emailRules"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions class="px-0">
          <Button
            text="Mandar Código"
            block
            aria-label="Mandar código"
            @event="resendConfirmationCode()"
          />
        </v-card-actions>
        <v-card-actions class="justify-start px-0">
          <v-btn
            text
            class="text-decoration-underline text-none font-weight-medium"
            @click="handleStep('register')"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-card v-else-if="stepRegister === 'verifyCode'" class="pa-8 pa-sm-12">
      <v-form
        ref="formCode"
        v-model="validFormCode"
        lazy-validation
        @submit.prevent="confirmSignUp"
      >
        <v-card-text class="pa-0">
          <div
            class="text-h3 text-sm-h2 text-center font-weight-bold pt-4 pb-6"
          >
            Código de verificación
          </div>
          <div class="text-h4 text-center pb-4 font-weight-medium">
            Revisa tu correo para obtener el código
          </div>
          <v-text-field
            v-model="code"
            outlined
            label="Código"
            color="secondary"
            class="v-input--is-focused"
            :rules="fillInput"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions class="pa-0">
          <Button
            text="Confirmar Cuenta"
            block
            :loading="loading"
            aria-label="Confirmar cuenta"
            @event="confirmSignUp()"
          />
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import { Auth } from "aws-amplify";
import {
  fillInput,
  preferedNameRules,
  stateRulesSelect,
  cityRulesSelect,
  legalNameRules,
  cityRules,
  emailRules,
  phoneRules,
  codeRules,
  passwordRules,
  confirmPasswordRules,
  checkBoxRules,
  maxDigitsPhone,
  maxDigitsCode,
} from "@/Utils/rules.js";
import { states, getCitiesByState } from "@/Utils/locations.js";
import { errorMessageCognito } from "@/Utils/awsCognito.js";
import { useMutation } from "@/graphql/index";
import { INSERT_USER } from "@/graphql/mutations";

export default {
  name: "FormRegister",
  components: {
    Button,
  },
  props: {
    redirectRegister: {
      type: String,
      default: "UserMainPage",
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      stepRegister: "register",
      loading: false,
      valid: true,
      validFormCode: true,
      showPassword: false,
      showPasswordConfirm: false,
      maxDigits: maxDigitsPhone,
      maxDigitsCode: maxDigitsCode,
      username: "",
      preferedName: "",
      email: "",
      phone: "",
      state: "",
      city: "",
      password: "",
      confirmPassword: "",
      termsConditions: false,
      privaciPolicies: false,
      code: "",
      states: states,
      fillInput: fillInput,
      preferedNameRules: preferedNameRules,
      stateRulesSelect: stateRulesSelect,
      cityRulesSelect: cityRulesSelect,
      legalNameRules: legalNameRules,
      cityRules: cityRules,
      emailRules: emailRules,
      phoneRules: phoneRules,
      codeRules: codeRules,
      passwordRules: passwordRules,
      confirmPasswordRules: confirmPasswordRules,
      checkBoxRules: checkBoxRules,
    };
  },
  computed: {
    citySelect: function () {
      return getCitiesByState(this.state);
    },
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword ||
        "Las contraseñas deben coincidir";
    },
  },
  methods: {
    stateUpdated() {
      this.city = "";
    },
    handleStep(step) {
      this.stepRegister = step;
    },
    handleAlert({ type, message }) {
      this.$store.dispatch("handleAlert", {
        type: type,
        message: message,
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
    changeType() {
      this.$emit("changeType", "login");
    },
    handleTermsRoute() {
      let route = this.$router.resolve({ name: "TermsOfServicePublic" });
      window.open(route.href, "_blank");
    },
    async resendConfirmationCode() {
      try {
        await Auth.resendSignUp(this.email);
        this.handleAlert({
          type: "success",
          message: "Código Enviado",
        });
        this.handleStep("verifyCode");
      } catch (error) {
        const message = errorMessageCognito(error, "Error al verificar cuenta");
        this.handleAlert({
          type: "error",
          message: message,
        });
        console.log(error);
      }
    },
    async register() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          await Auth.signUp({
            username: this.email,
            password: this.password,
          });
          this.handleStep("verifyCode");
        } catch (error) {
          const message = errorMessageCognito(
            error,
            "Error al registrar la cuenta"
          );
          this.handleAlert({
            type: "error",
            message: message,
          });
          console.log(error);
        }
        this.loading = false;
      }
    },
    async confirmSignUp() {
      if (this.$refs.formCode.validate()) {
        this.loading = true;
        try {
          await Auth.confirmSignUp(this.email, this.code).then(async (data) => {
            if (data) {
              await this.saveUser();
            }
          });
        } catch (error) {
          const message = errorMessageCognito(error, "Error al crear cuenta");
          this.handleAlert({
            type: "error",
            message: message,
          });
          console.log(error);
        }
        this.loading = false;
      }
    },
    async saveUser() {
      const { data, errors } = await useMutation(INSERT_USER, {
        name: this.username,
        preferedName: this.preferedName,
        email: this.email,
        state: this.state,
        city: this.city,
        phone: this.phone,
      });
      if (data) {
        await this.signIn();
      } else if (errors) {
        console.log(errors);
        this.handleAlert({
          type: "error",
          message: "Error al crear cuenta",
        });
      }
    },
    async signIn() {
      try {
        if (this.email && this.password) {
          const user = await Auth.signIn(this.email, this.password);
          await this.$store.dispatch("saveCognitoUser", user);
          await this.$store.dispatch("fetchUser");
          this.$router.push({ name: this.redirectRegister });
        } else {
          this.changeType();
        }
        this.handleAlert({
          type: "success",
          message: "Registro Exitoso",
        });
      } catch (error) {
        await Auth.signOut();
        const message = errorMessageCognito(error, "Error al ingresar");
        this.handleAlert({
          type: "error",
          message: message,
        });
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-background-transparent::before {
  background-color: transparent !important;
}

.hover-close-btn:hover .v-icon {
  transform: scale(1.1);
}
</style>
