// docs aws errors cognito https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pool-managing-errors.html

export const errorMessageCognito = (error, defaultMessage) => {
  let message = "";
  switch (error.code) {
    case "UserNotConfirmedException":
      message = "Cuenta sin Verificar";
      break;

    case "NotAuthorizedException":
      switch (error.message) {
        case "Password attempts exceeded":
          message =
            "Se ha excedido el numero de intentos. Por favor, inténtelo más tarde.";
          break;

        case "Incorrect username or password.":
          message = "Correo o Contraseña Incorrectos";
          break;

        default:
          message = defaultMessage;
          break;
      }
      break;

    case "UserNotFoundException":
      message = "Correo electrónico y/o contraseña ingresados son incorrectos";
      break;

    case "CodeDeliveryDetails":
      message = "No existe cuenta con ese correo";
      break;

    case "UsernameExistsException":
      message = "El correo ingresado ya está asociado a una cuenta";
      break;

    case "CodeMismatchException":
      message = "Código no válido";
      break;

    case "InvalidParameterException":
      switch (error.message) {
        case "User is already confirmed.":
          message = "Usuario ya confirmado";
          break;

        default:
          message = "Usuario no encontrado";
          break;
      }
      break;

    case "LimitExceededException":
      message = "Se ha excedido el numero de intentos";
      break;

    default:
      message = defaultMessage;
      break;
  }
  return message;
};
