<template>
  <v-container class="py-0">
    <v-row
      v-if="!loadingFirstCards"
      no-gutters
      class="my-3 justify-center justify-sm-start"
    >
      <v-col
        v-for="(card, i) in cards"
        :key="i"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="pa-6 pa-md-5 d-flex justify-center"
      >
        <v-lazy
          :options="{ threshold: 1 }"
          transition="scroll-y-reverse-transition"
          class="d-flex justify-center"
        >
          <Card :card="card" :type="type" />
        </v-lazy>
      </v-col>
    </v-row>
    <v-lazy
      v-if="noMoreCards"
      :options="{ threshold: 1 }"
      transition="scroll-y-reverse-transition"
    >
      <div class="pt-3 text-center">
        <v-btn text icon @click="scrollToTop">
          <v-icon small class="descent--text text--darken-2">
            fa-chevron-up
          </v-icon>
        </v-btn>
        <p
          class="mt-1 mt-md-2 descent--text text--darken-2 text-h4 font-weight-regular"
        >
          Has llegado al final de la lista
        </p>
      </div>
    </v-lazy>
    <div
      v-if="!loadingFirstCards && !loadingMoreCards && !noMoreCards"
      v-intersect.quiet="showMoreCards"
      class="my-10"
    ></div>
  </v-container>
</template>

<script>
import Card from "@/components/Shared/Card.vue";

export default {
  name: "InfiniteListCards",
  components: {
    Card,
  },
  props: {
    cards: { type: Array, required: true },
    type: { type: String, required: true },
    noMoreCards: { type: Boolean, required: true },
    loadingFirstCards: { type: Boolean, required: true },
    loadingMoreCards: { type: Boolean, required: true },
  },
  methods: {
    showMoreCards() {
      this.$emit("handleMoreCards");
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style></style>
