<template>
  <div>
    <v-app-bar
      :height="$vuetify.breakpoint.xs ? 70 : 88"
      :flat="
        !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ||
        page !== 'main-page'
      "
      :color="
        ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) &&
        page === 'main-page'
          ? 'primary'
          : 'transparent'
      "
      class="px-2 px-sm-5"
    >
      <v-app-bar-nav-icon
        v-if="page === 'landing' || page === 'main-page'"
        dark
        :ripple="false"
        class="hidden-md-and-up"
        @click="drawer = true"
      />
      <v-btn
        depressed
        plain
        dark
        :ripple="false"
        :to="{ name: 'MainPage' }"
        class="hidden-sm-and-down pl-0"
      >
        <v-img src="@/assets/icons/LogoZaturnaHorizontal.svg" />
      </v-btn>
      <v-toolbar-items
        v-if="page === 'landing' || page === 'main-page'"
        class="hidden-sm-and-down align-center"
      >
        <span v-for="(section, i) in desktopNav" :key="i">
          <v-btn
            v-if="!section.menu"
            depressed
            text
            dark
            :ripple="false"
            class="text-h4 btn-background-transparent v-btn--active"
            active-class="navbar-active font-weight-bold"
            router
            :exact="section.title === 'Inicio'"
            :to="{ name: section.routerName }"
          >
            <span class="navbar-title">{{ section.title }}</span>
          </v-btn>
          <v-menu
            v-else
            v-model="section.menuHandler"
            offset-y
            rounded="md"
            transition="slide-y-transition"
            open-on-hover
            :close-on-content-click="false"
            :open-delay="350"
            :close-delay="100"
            min-width="658"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                text
                dark
                :ripple="false"
                class="text-caption btn-background-transparent v-btn--active"
                :class="section.menuHandler && 'navbar-active font-weight-bold'"
                active-class="navbar-active font-weight-bold"
                router
                :to="{ name: section.routerName }"
                v-bind="attrs"
                v-on="on"
              >
                <span class="navbar-title">{{ section.title }}</span>
              </v-btn>
            </template>
            <v-card class="py-6 px-9">
              <v-card-title class="pa-0 font-weight-bold text-h5">
                Servicios Zaturnas
              </v-card-title>
              <div class="my-2 ml-1 font-weight-bold text-subtitle-1">
                Los más populares
              </div>
              <v-card-text class="pa-0 text-subtitle-1">
                <v-row class="ma-0">
                  <v-col
                    cols="5"
                    class="px-4 py-2 purple-gradient-background rounded-md"
                    style="width: 234px"
                  >
                    <!-- //! Change way of determining the most popular services array -->
                    <div
                      v-for="(item, idx) in section.menu.slice(0, 6)"
                      :key="idx"
                      :class="idx == 0 ? 'mt-0' : 'mt-2'"
                    >
                      <v-hover v-slot="{ hover }">
                        <router-link
                          :to="{
                            name: section.routerName,
                            query: {
                              tipo: item,
                            },
                          }"
                          class="text-decoration-none black--text"
                          :class="
                            hover
                              ? 'text-decoration-underline font-weight-medium'
                              : ''
                          "
                        >
                          <span @click="section.menuHandler = false">
                            {{ item }}
                          </span>
                        </router-link>
                      </v-hover>
                    </div>
                  </v-col>
                  <v-col cols="7" class="px-5 py-2 two-column-list">
                    <div
                      v-for="(item, idx) in section.menu.slice(6, 17)"
                      :key="idx"
                      class="ml-5"
                      :class="idx == 0 ? 'mt-0' : 'mt-2'"
                    >
                      <v-hover v-slot="{ hover }">
                        <router-link
                          :to="{
                            name: section.routerName,
                            query: {
                              tipo: item,
                            },
                          }"
                          class="text-decoration-none black--text"
                          :class="
                            hover &&
                            'text-decoration-underline font-weight-medium'
                          "
                        >
                          <span @click="section.menuHandler = false">
                            {{ item }}
                          </span>
                        </router-link>
                      </v-hover>
                    </div>
                    <div class="ml-5 mt-2">
                      <v-hover v-slot="{ hover }">
                        <router-link
                          :to="{
                            name: section.routerName,
                            params: { autofocusServices: true },
                          }"
                          class="text-decoration-none font-weight-bold black--text"
                          :class="hover && 'text-decoration-underline'"
                        >
                          <span @click="section.menuHandler = false">
                            Ver todos
                          </span>
                        </router-link>
                      </v-hover>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-menu>
        </span>
      </v-toolbar-items>
      <v-spacer />
      <v-toolbar-items v-if="page === 'landing' || page === 'main-page'">
        <v-btn
          depressed
          text
          dark
          :ripple="false"
          class="hidden-sm-and-down text-caption btn-background-transparent"
          :to="{ name: 'MainPageProviders' }"
        >
          <span class="navbar-title font-weight-bold">
            Ir a modo proveedor
          </span>
        </v-btn>
        <v-container class="pa-1 d-flex align-center">
          <Button
            text="Iniciar sesión"
            glass
            rounded="md"
            :height="40"
            :elevation="2"
            :horizontal-padding="4"
            aria-label="Abrir ventana de inicio de sesión"
            @event="login()"
          />
        </v-container>
      </v-toolbar-items>
      <v-toolbar-items v-else-if="page === 'info-providers'">
        <v-container class="pa-1 d-flex align-center">
          <v-btn
            depressed
            text
            dark
            :ripple="false"
            :to="{ name: 'MainPage' }"
            class="text-caption btn-background-transparent v-btn--active px-0"
          >
            <span
              class="navbar-title font-weight-bold text-decoration-underline"
            >
              Modo cliente
            </span>
          </v-btn>
        </v-container>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-btn
        :height="$vuetify.breakpoint.xs ? 70 : 88"
        depressed
        plain
        :ripple="false"
        class="d-flex justify-center primary rounded-0 v-btn--active"
        :to="{ name: 'MainPage' }"
        @click="drawer = false"
      >
        <v-img
          :height="$vuetify.breakpoint.xs ? 45 : 53"
          contain
          src="@/assets/icons/LogoZaturnaHorizontal.svg"
        />
      </v-btn>
      <v-list class="py-0">
        <v-list-item-group v-model="group" active-class="secondary--text">
          <v-list-item
            v-for="(item, i) in mobileNavbarTop"
            :key="i"
            router
            :to="{ name: item.routerName }"
            :exact="item.title === 'Inicio'"
          >
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-list class="py-0">
          <v-list-item-group v-model="group" active-class="secondary--text">
            <v-list-item
              v-for="(item, i) in mobileNavbarBottom"
              :key="i"
              router
              :to="{ name: item.routerName }"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>
    <ModalLoginRegister
      :dialog="dialog"
      :modal-type="modalType"
      @closeModal="handleModal"
      @handleModalType="handleModalType"
    />
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import ModalLoginRegister from "./Modal/ModalLoginRegister.vue";

export default {
  name: "Navbar",
  components: { Button, ModalLoginRegister },
  props: {
    purpleBkg: {
      type: Boolean,
      default: false,
    },
    page: { type: String, default: "landing" },
  },
  data: () => ({
    dialog: false,
    modalType: "",
    drawer: false,
    group: null,
    mobileNavbarTop: [
      {
        title: "Inicio",
        routerName: "MainPage",
      },
      {
        title: "Proveedores",
        routerName: "Providers",
      },
      {
        title: "Servicios",
        routerName: "Services",
      },
      {
        title: "Paquetes",
        routerName: "Packages",
      },
    ],
    mobileNavbarBottom: [
      {
        title: "Ir a modo proveedor",
        routerName: "MainPageProviders",
        icon: "fa-house-user",
      },
    ],
    desktopNav: [
      {
        title: "Inicio",
        routerName: "MainPage",
      },
      {
        title: "Proveedores",
        routerName: "Providers",
      },
      {
        title: "Servicios",
        routerName: "Services",
        // menuHandler: false,
        // menu: [
        //   "Salones de eventos",
        //   "Mobiliario",
        //   "Diseño de eventos",
        //   "Música",
        //   "Fotógrafos",
        //   "Pastelerías",
        //   "Ambientación",
        //   "Banquetes",
        //   "Carpas",
        //   "Catering",
        //   "Entretenimiento",
        //   "Iluminación",
        //   "Invitaciones",
        //   "Mesas de dulces",
        //   "Pistas de baile",
        //   "Recuerdos",
        //   "Video",
        // ],
      },
      {
        title: "Paquetes",
        routerName: "Packages",
      },
    ],
    menu: [
      {
        title: "Mi perfil",
        routerName: "Home",
        bold: true,
      },
      {
        title: "Ayuda",
        routerName: "Home",
      },
      {
        title: "Cerrar sesión",
        routerName: "Home",
      },
    ],
  }),
  methods: {
    login() {
      this.handleModalType("login");
      this.handleModal();
    },
    handleModalType(type) {
      this.modalType = type;
    },
    handleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn {
  span {
    font-size: 18px !important;
  }
  &:hover {
    .navbar-title {
      transform: scale(1.01);
    }
  }
  &.navbar-active .navbar-title {
    transform: scale(1.01);
  }
}

.btn-cristal {
  background: rgba(255, 255, 255, 0.3) !important;
}

.slide-y-transition-leave-to {
  transform: none;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}

.purple-gradient {
  background: white
    linear-gradient(
      to top,
      rgba(98, 37, 130, 0.6) 1%,
      rgba(98, 37, 130, 1) 100%
    );
  background-size: cover !important;
}

.purple-gradient-background {
  background: linear-gradient(
    to top,
    rgba(98, 37, 130, 0.2) 0%,
    rgba(196, 196, 196, 0) 90%
  );
}

.two-column-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>
