var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"fill-height purple-gradient"},[_c('Navbar'),_c('v-container',{staticClass:"px-5 px-sm-9"},[_c('SearchBar')],1)],1),_c('div',{staticClass:"body-container",style:([
      _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
        ? { 'padding-bottom': '30px' }
        : { 'padding-bottom': '70px' } ])},[_c('v-container',{staticClass:"px-10 px-md-auto"},[_c('div',[_c('div',{staticClass:"text-h2 text-md-h1 font-weight-bold mx-0"},[_vm._v(" Proveedores Zaturna ")]),_c('div',{staticClass:"flex-row text-subtitle-1 text-sm-h4 font-weight-regular mb-4 ml-md-1",class:_vm.loadingFirstProviders && 'mb-16 mb-sm-12 pb-2 pb-sm-0'},[(!_vm.loadingFirstProviders)?_c('div',[_c('span',{staticClass:"d-flex d-sm-inline-flex"},[_vm._v(" "+_vm._s(_vm.providersQuantity !== 1 ? _vm.providersQuantity + " proveedores " + (_vm.filtersUsed ? "encontrados" : "registrados") : _vm.providersQuantity + " proveedor " + (_vm.filtersUsed ? "encontrado" : "registrado"))+" ")]),_c('span',{staticClass:"d-flex d-sm-inline-flex mx-sm-8"},[_c('v-icon',{staticClass:"mr-2 secondary--text",attrs:{"small":""}},[_vm._v("fa-map-pin")]),_vm._v(" "+_vm._s(_vm.citiesQuantity != 1 ? _vm.citiesQuantity + " ciudades" : _vm.citiesQuantity + " ciudad")+" ")],1)]):_vm._e()])]),_c('v-divider',{staticClass:"mx-n2 secondary"}),_c('CardsFilters',{attrs:{"filters":_vm.filters,"filters-used":_vm.filtersUsed},on:{"removeFilters":_vm.removeFilters}}),(
          !_vm.loadingFirstProviders &&
          !_vm.loadingMoreProviders &&
          _vm.providersQuantity === 0
        )?_c('div',{staticClass:"my-16 text-center descent--text text--darken-2 text-h4 font-weight-regular"},[_vm._v(" No se han encontrado proveedores ")]):_vm._e()],1),(!_vm.filtersUsed || _vm.providersQuantity !== 0)?_c('InfiniteListCards',{attrs:{"cards":_vm.providers,"no-more-cards":_vm.noMoreProviders,"loading-first-cards":_vm.loadingFirstProviders,"loading-more-cards":_vm.loadingMoreProviders,"type":"Provider"},on:{"handleMoreCards":_vm.fetchMoreProviders}}):_vm._e(),(_vm.loadingFirstProviders || _vm.loadingMoreProviders)?_c('v-container',{staticClass:"d-flex flex-column align-center my-10 my-sm-12"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary","size":100}})],1):_vm._e()],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }